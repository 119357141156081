@import './Styles/About.scss';
@import './Styles/Header.scss';
@import './Styles/Footer.scss';
@import './Styles/Content.scss';
@import './Styles/Tools.scss';

body {
  margin: 0;
  padding: 0;
}

section, footer {
  padding: 4rem 0rem;
  padding-right: 60px;
  padding-left: 60px;

  @media only screen and (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;

    .mantine-Container-root {
        padding: 0px;
    }
  }
}

@font-face {font-family: "BunkenSemiBold";
  src: url("Fonts/f563d2856de753a03705d43869ed02fb.eot"); /* IE9*/
  src: url("Fonts/f563d2856de753a03705d43869ed02fb.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("Fonts/f563d2856de753a03705d43869ed02fb.woff2") format("woff2"), /* chrome、firefox */
  url("Fonts/f563d2856de753a03705d43869ed02fb.woff") format("woff"), /* chrome、firefox */
  url("Fonts/f563d2856de753a03705d43869ed02fb.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("Fonts/f563d2856de753a03705d43869ed02fb.svg#Bunken Tech Sans W01 SemiBold") format("svg"); /* iOS 4.1- */
}

@font-face {
  font-family: 'BunkenLight';
  src: local('Bunken Tech Sans Pro Light'), local('Bunken-Tech-Sans-Pro-Light'),
      url('Fonts/BunkenTechSansPro-Light.woff2') format('woff2'),
      url('Fonts/BunkenTechSansPro-Light.woff') format('woff'),
      url('Fonts/BunkenTechSansPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

.bunkenSemiBold {
  font-family:"BunkenSemibold" !important;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.bunkenLight {
  font-family:"BunkenLight" !important;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
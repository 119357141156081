#tools {
    margin-top: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0px;
    
    a {
        color: inherit !important;
    }

    @media only screen and (max-width: 425px) {
        .mantine-Container-root {
            padding: 0px;
        }
    }
}
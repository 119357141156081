#products {
    h2,h3 { margin: 0; padding: 0 }
    
    @media only screen and (max-width: 769px) {
        .mantine-Carousel-root {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    
    .imageHalfStyle {
        padding: 0% 15%;
        // marginLeft: 'auto';
        // marginRight: 'auto';
        height: 50%;
    }
    .imageHalfStyle img {
        max-height: 100%;
        max-width: 100%;
    }

    .iconHalfStyle {
        padding: 0% 15%;
        margin: auto;
        height: 50%;
    }
}
header {
    position: absolute;
    z-index: 99;

    .navbar {
        display: flex;
        flex-direction: row;
        gap: 25px;
        align-items: center;

        .navbar-item {
            cursor: pointer;
            font-weight: 500;
            
                // a {
                //     color: #2d2d2d;
                //     transition: all 0.1s ease;
                // }
            
                // a:hover {
                //     color: #fab005;
                //     text-decoration: none;
                // }
        }
    }

    .logo-container img {
        margin-bottom: 0;
        max-width: 33vw;
    }

    .content-desktop {
        justify-content: space-between;
        display: flex;
        align-items: center;
    }

    .content-mobile {
        justify-content: space-between;
        display: flex;
        align-items: center;

        .burger-button {
            float: right;
        }
    }

    /* Responsive header */
    @media only screen and (min-width: 769px) {
        .content-desktop {
            display: flex;
        }

        .content-mobile {
            display: none;
        }

        top: 60px;
        left: 60px;
        right: 60px;
    }

    @media only screen and (max-width: 768px) {
        .content-desktop {
            display: none;
        }

        .content-mobile {
            display: flex;
        }

        top: 30px;
        left: 20px;
        right: 20px;
    }
}

.menu {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between !important;
    gap: 30px;

    .menu-items {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .menu-item {
            cursor: pointer;

            a {
                color: inherit !important;
                text-decoration: underline;
            }
        }
    }
}

.selectedBtn {
    //background-color: #003eaa !important;
    outline: 1px solid #4187ff79;
}


@media only screen and (max-width: 992px) {
    .scroll_buttons {
        display: none;
    }
}

@media only screen and (min-width: 992px) {
    .scroll_buttons {
        display: block;
    }
}